<template>
    <div class="form-con">
        <div class="head_con">
            <h1 v-for="(v,i) in headlist" :key="i" :class="[(active===v.id)?'active':'']" @click="active=v.id">{{v.name}}</h1>   
        </div>
        <div style="width:386px;margin:0 auto;">
            <el-form  :model="mmform" ref="mmform">
                <el-form-item prop="extloginname" v-if="active==='1'" key="1" :rules="[{ required: true, message: '请输入手机号'},{ validator: checkAge, trigger: 'blur'}]">
                    <el-input type="text" v-model="mmform.extloginname" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item prop="extpwd" v-if="active==='1'" key="2" :rules="{ required: true, message: '请输入密码'}">
                    <el-input type="password" v-model="mmform.extpwd" placeholder="请输入密码" show-password></el-input>
                </el-form-item>
                <el-form-item prop="extmobile" v-if="active==='2'" key="3" :rules="[{ required: true, message: '请输入手机号'},{ validator: checkAge, trigger: 'blur'}]">
                    <el-input type="text" v-model="mmform.extmobile" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item prop="validate" v-if="active==='2'" key="4" :rules="{ required: true, message: '请输入验证码'}">
                    <el-input type="text" v-model="mmform.validate" placeholder="请输入验证码">                
                        <span @click="getYzm()" slot="suffix"  class="yzcode-r" :loading="loadingController" :disabled="inputDisabled" :style="{'pointer-events':inputDisabled}">
                            {{text}}
                        </span>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="conline">
            <span class="wjmm"  v-if="active==='1'">忘记密码</span>
            <a class="yhzc" href="/sign">用户注册</a>
        </div>
        <div class="btn-action-row">
            <a @click="submitHandler('mmform')">登录</a>
        </div>
    </div>
</template>

<script>   
import {  mapMutations , mapGetters } from 'vuex';

export default {
    head: {
        title: '登录'
    },
    layout: 'login',
    data() {
        var that = this;
        return {
            text:'发送验证码',
            loadingController:false,
            inputDisabled:'auto',
            active:'1',
            visible:false,
            mobilehead:'+86',
            mmform:{
                extloginname:'',
                extpwd:'',
                extmobile:'',
                validate:''
            },
            checked:'',
            headlist:[
                {name:'密码登录',id:'1'},
                {name:'验证码登录',id:'2'}
            ]
        }
    },
    methods: {
        ...mapMutations({
            updateUserInfo: 'userinfo/updateUserInfo',
        }),

        checkAge (rule, value, callback) {
            if(!/^[1][0-9]{10}$/.test(value)){
                callback(new Error('请输入正确的手机号'));
            }       
            callback();
        },
        getYzm() {
            if(!this.mmform.extmobile ||  !/^[1][0-9]{10}$/.test(this.mmform.extmobile)) {
                ShowMsg('请确认手机号', 'warning');
                return;
            };
            this.$post(`${this.$store.state.api.sysUrl}/user/sendyzm.json`, {
                phonenum: this.mmform.extmobile
            }, data => {
                //倒计时
                this.loadingController = true;
                this.inputDisabled = 'none';
                //倒计时
                var remain = 60;
                this.text = '请在' + remain + 's后重试'

                var int = setInterval(() => {
                    if (remain != 0) {
                        remain--
                        this.text = '请在' + remain + 's后重试'
                    } else {
                        clearInterval(int);
                        this.text = '获取手机验证码';
                        this.inputDisabled = 'auto';
                        this.loadingController = false;
                    }
                }, 1000)

            })
        },
        submitHandler(mmform) {
            var that = this;
            this.$refs[mmform].validate((valid)=> {
                if (valid) {
                    if(this.active==='1'){
                        this.$post(`${this.$store.state.api.sysUrl}/user/login.json`,this.mmform, function (data, res) {

                            console.log(data,res);
                            that.updateUserInfo(data);
                            ShowMsg(res.msg,'success');
                            window.location.assign('/');
                        });
                    }else{
                        this.$post(`${this.$store.state.api.sysUrl}/user/phonelogin.json`,this.mmform, function (data, res) {

                            console.log(data,res);
                            that.updateUserInfo(data);
                            ShowMsg(res.msg,'success');
                            window.location.assign('/');
                        });
                    }
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .form-con{
        width: 100%;
        font-family: Microsoft YaHei;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        padding-bottom:48px;
        & input::-webkit-input-placeholder {
            color: #d1d1d2;
        }

        .iptcon{
            width:386px;
            height:58px;
            border-radius: 4px;
            border: solid 1px #d6d8dc;
            margin: auto;
            input{
                width:380px;
                height:56px;  
                margin:0 auto;
                margin-left:2px;
                padding-left:15px;
                outline: none;
                border:none;
            }
        } 
        .conline{
            width: 386px;
            height:19px;
            margin:0 auto;
            margin-top:12px;
            .yhzc{
                float:right;
                margin:0 3px;
                cursor: pointer;
                color: #FFB73B;
                font-size: 14px;
            }
            .wjmm{
                cursor: pointer;
                color: #666666;
                font-size: 14px;
            }
            .yhzc:hover{
                color:#4988CF;
            }
        }
        .head_con{
            width: 100%;
            height:75px;
            line-height:75px;
            color: #4988cf;
            font-size: 22px;
            text-align: center;
            border-bottom: 1px solid #e9e9e9;
            margin-bottom: 37px;
            h1{
                cursor: pointer;
                width: 50%;
                height:75px;
                line-height:75px;
                float: left;
                color: #999999;
                font-size: 22px;
                text-align: center;               
                margin-bottom: 37px;
            }
            h1:hover{
                color: #4988cf;
            }
            .active{
                color: #4988cf;
                border-bottom: 4px solid #4988cf;
            }
        }

        .sign-bottom{
            width: 144px;
            height:21px;
            margin:0 auto;
            margin-top:22px;
            font-size: 16px;
            a{
                cursor: pointer;
                color: #FFB73B;
            }
            a:hover{
                color:#4988CF;
            }
        }
        ::v-deep {
            .el-input__inner{
                line-height: 58px;
                height: 58px;
                font-size: 14px;
            }
            .el-button{
                width: 100%;
            }
        }

        .action-row{
            margin-bottom: 30px;
            overflow: hidden;

            a{
                float: right;
                color: #ffb534;
            }
        }

        .btn-action-row{
            margin:0 auto;
            margin-top: 52px;
            width: 386px;
            height: 62px;
            background-color: #4988cf;
            border-radius: 4px;
            a{
                display: block;
                width:100%;
                height:100%;
                font-size: 22px;              
                text-align: center;
                line-height:62px;
                color: #ffffff;
            }
        }

        
        .yzcode-r{
            display:block;margin-top:10px; margin-right:30px;color:#4988cf;cursor:pointer;
        }
        .btn-submit{
            font-size: 18px;
            height:54px;
        }

        ::v-deep .btn-row{
            margin-bottom: 0;
        }
    }
</style>